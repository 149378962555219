html,
body {
  margin: 0;
  height: 100%;
}

#root {
  display: flex;
  flex: 1;
  height: 100%;
}
